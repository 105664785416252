import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import MainContainer from "../MainContainer/MainContainer";
import MainSlider from "../MainSlider/MainSlider";
import MediaSliderSection from "../MediaSliderSection/MediaSliderSection";
import "./Header.scss";
import { useTranslation } from "react-i18next";

type Props = {
  sectionData: any;
  sliderData: any;
  type: any;
};

const Header = ({ sectionData, sliderData, type }: Props) => {
  const { t, i18n } = useTranslation();

  const [slidersNo, setSlidersNo] = useState("");
  const [oldSlide, setOldSlide] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlide2, setActiveSlide2] = useState(0);
  useEffect(() => {
    setSlidersNo(sliderData?.length);
  }, [sliderData]);

  let settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    pauseOnHover: false,
    fade: true,

    cssEase: "cubic-bezier(0.600, -0.280, 0.735, 0.045)",
    // cssEase: "linear",
    lazyLoad: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    vertical: false,
    verticalSwiping: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (current: any, next: any) => {
      setOldSlide(current);
      setActiveSlide(next);
    },
    afterChange: (current: any) => setActiveSlide2(current),
  };

  return (
    <Box
      sx={{
        height: "155vh",
        minHeight: "1400px",
        maxHeight: "1600px",
        width: "100%",
        position: "relative",
        
        
        "&::after": {
          content: "''",
          position: "absolute",
          bottom: "0",
          right: "0",
          width: "100%",
          height: "280px",
          backgroundImage: "linear-gradient(to top, #37245b 30px, rgba(0,0,0,0.00) )",
          zIndex: "10",
        },
        
        "@media (max-width: 575px)": {
          bottom: "0",
          minHeight: "1300px",
          "&::after": {
            height: "180px",
            bottom: "0",
          }
        }
        // "@media (max-height:800px)": {
        //   height: "1250px",
        // },
        // "@media (min-height:1350px)": {
        //   height: "1600px",
        // },
      }}
    >
      <MainSlider
        activeSlide={activeSlide}
        settings={settings}
        data={sliderData?.length > 0 && sliderData}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: "10%",
          width: "100%",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: "11", 
          "@media (min-width:768px)": {
            bottom: "0"
          }
        }}
      >
        <MainContainer>
          <MediaSliderSection
            movies={sectionData?.movies}
            title={
              i18n.language === "en"
                ? sectionData?.title?.txt
                : sectionData?.title?.txt_ar
            }
            subTitle={
              i18n?.language === "en"
                ? sectionData?.description?.txt
                : sectionData?.description?.txt_ar
            }
            moreButton={sectionData?.id}
            actions={true}
            color="#81CEFC"
            categoryEnum={1}
          />
        </MainContainer>
      </Box>
    </Box>
  );
};

export default Header;
