import { Box, ButtonGroup, Collapse, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../../store/cart-slice";
import PaymentButton from "../Buttons/PaymentButton";
import OnlinePaymentMethods from "../PaymentMethods/OnlinePaymentMethods";
import SearchTextField from "../SearchTextField/SearchTextField";
import ChooseAddressContainer from "./ChooseAddressContainer";
import OrderComponent from "./OrderComponent";

const ShoppingCartSideBox = () => {
  const { t } = useTranslation();

  const translationPath = "purchases.payment.";
  const circles = [
    "#FFAD0A",
    "rgba(255, 255, 255, .5)",
    "rgba(255, 255, 255, .5)",
  ];

  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState(3);

  const cartState = useSelector((state: any) => state.cart);

  const handleChangePaymentMethod = (id: number) => {
    setPaymentMethod(id);
    dispatch(cartActions.setPaymentMethod(id));
  };

  // //get shipping prices and save them in redux store
  // const { data: shippingData } = useGetData("order/get_shipping_cost", []);
  // useEffect(() => {
  //   dispatch(cartActions.setShippingData(shippingData?.list));
  // }, []);

  const authState = useSelector((state: any) => state.auth);

  return (
    <>
      <Box
        sx={{
          flexShrink: 0,
          height: "inherit",
          background:
            "linear-gradient(180deg, rgba(117, 87, 152, 1) 0%, rgba(59, 44, 76, 1) )",
          position: "relative",
          padding: "45px 60px",
          "@media (max-width: 1024px)": {
            order: 2,
            padding: "45px 30px",
          },
          "@media (max-width: 1300px)": {
            padding: "45px 20px",
          },
        }}
      >
        <Stack
          sx={{
            width: {
              xs: "42px",
              xl: "48px",
              xxl: "56px",
            },
            height: {
              xs: "180px",
              xl: "190px",
            },
            background:
              "linear-gradient(180deg, rgba(117, 87, 152, 1) 0%, rgba(59, 44, 76, 1) )",
            position: "absolute",
            top: "0",
            right: { md: -41, xl: -47, xxl: -55 },
            display: { mobile: "none", md: "flex" },
            borderStartEndRadius: "12px",
            borderEndEndRadius: "12px",
            justifyContent: "space-evenly",
            alignItems: "center",
            padding: "20px 0",
          }}
        >
          {circles.map((circle, index) => (
            <Box
              key={index}
              sx={{
                bgcolor: circle,
                width: "22px",
                height: "22px",
                borderRadius: "50%",
              }}
            />
          ))}
        </Stack>

        <Stack
          sx={{ justifyContent: "space-around", height: "100%", gap: "3rem" }}
        >
          <Stack
            sx={{
              height: "100%",
            }}
            spacing={6}
          >
            {authState.isVip && (
              <Stack
                sx={{
                  rowGap: {
                    mobile: "22px",
                    sm: "28px",
                    md: "34px",
                    lg: "40px",
                    xl: "46px",
                    xxl: "52px",
                  },
                }}
              >
                <MainCartTitle title={t(`${translationPath}method`)} />
                <ButtonGroup
                  sx={{
                    alignSelf: "center",
                    width: {
                      mobile: "100%",
                      sm: "300px",
                      md: "100%",
                    },
                    borderRadius: "12px",
                    overflow: "hidden",
                    background: "white",
                    height: {
                      mobile: "45px",
                      sm: "55px",
                      md: "60px",
                      lg: "72px",
                    },
                    gap: "2px",
                  }}
                >
                  <PaymentButton
                    onClick={() => {
                      handleChangePaymentMethod(1);
                    }}
                    bgcolor={
                      paymentMethod === 1 ? "#E8D6FF" : "rgba(255, 255, 255, 1)"
                    }
                    boxShadow={
                      paymentMethod === 1
                        ? "0px 0px -12px #0000003B !important"
                        : ""
                    }
                    width={"100%"}
                    btnText={t(`${translationPath}cash`)}
                    borderRadius={"12px !important"}
                  />
                  <PaymentButton
                    onClick={() => {
                      handleChangePaymentMethod(3);
                    }}
                    bgcolor={
                      paymentMethod === 4 ? "#E8D6FF" : "rgba(255, 255, 255, 1)"
                    }
                    boxShadow={
                      paymentMethod === 4
                        ? "0px 0px 12px #0000003B; !important"
                        : ""
                    }
                    borderRadius={"12px !important"}
                    btnText={t(`${translationPath}card`)}
                    width={"100%"}
                  />
                </ButtonGroup>
              </Stack>
            )}

            <ChooseAddressContainer />
            <ClientNote />
          </Stack>
          {
            <Collapse
              sx={{
                width: "100%",
              }}
              in={
                cartState?.payment_method === 3 ||
                cartState?.payment_method === 4
              }
            >
              <OnlinePaymentMethods />
            </Collapse>
          }

          <OrderComponent />
        </Stack>
      </Box>
    </>
  );
};

export default ShoppingCartSideBox;

const ClientNote = () => {
  const { t } = useTranslation();
  const cartState = useSelector((state: any) => state.cart);
  const { control } = useForm({
    values: {
      client_note: cartState?.client_note,
    },
  });
  // console.log("cartState?.client_note", cartState?.client_note);

  return (
    <Stack spacing={3}>
      <MainCartTitle title={t("profile.client_note")} />

      <SearchTextField
        label={t("profile.client_note")}
        name="client_note"
        control={control}
        multiline
      />
    </Stack>
  );
};

const MainCartTitle = ({ title }: { title: string }) => {
  return (
    <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
      <Box
        sx={{
          bgcolor: "#FFAD0A",
          width: "22px",
          height: "22px",
          borderRadius: "50%",
        }}
      />
      <Typography
        sx={{
          color: "rgba(232, 214, 255, 1)",
          fontSize: {
            mobile: "18px",
            xs: "24px",
            sm: "30px",
            md: "34px",
            lg: "28px",
            xl: "34px",
          },
          fontWeight: "medium",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
