import { Box, Stack } from "@mui/material";
import { motion } from "framer-motion";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import playButton from "../../Assets/Images/play-button.svg";
import ActorCard from "../../Components/Blocks/ActorComponents/ActorCard";
import CustomPagination from "../../Components/Blocks/CustomPagination/CustomPagination";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import MediaHeader from "../../Components/Blocks/MediaHeader/MediaHeader";
import MediaListGrid from "../../Components/Blocks/MediaListGrid/MediaListGrid";
import MediaListSlider from "../../Components/Blocks/MediaListSlider/MediaListSlider";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import StyledSliderWithIcon from "../../Components/StyledComponents/StyledSliderWithIcon/StyledSliderWithIcon";
import TitleWithIcon from "../../Components/StyledComponents/TitleWithIcon/TitleWithIcon";
import useGetData from "../../Hooks/useGetData";
import { trailerActions } from "../../store/trailer-slice";
import StyledMainBox from "../MoviesPage/Styles/StyledMainBox";
import StyledSliderContainer from "../MoviesPage/Styles/StyledSliderContainer";
import changeImageQuality from "../../Helpers/changeImageQuality";

type Props = {};

const SeasonPage = (props: Props) => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const { id } = useParams<string>();
  const { data, loading, error } = useGetData(
    `series/season/show/${id}?page=${query?.page || 1}`,
    [id, query?.page, i18n.language]
  );

  const dispatch = useDispatch();
  const trailerState = useSelector((state: any) => state.trailer);

  if (loading) return <MainLoader />;
  if (error) return <ErrorComponent />;
  let tags = [
    // {
    //   name: "Tags",
    //   name_ar: "التصنيف",
    //   label_value: data?.season?.season_data?.media_tags,
    //   label_value_ar: data?.season?.season_data?.media_tags,
    // },
    {
      name: "Product No",
      name_ar: "رقم المنتج",
      label_value: data?.season?.season_data?.season_serial,
      label_value_ar: data?.season?.season_data?.season_serial,
    },
    {
      name: "Quality",
      name_ar: "الجودة",
      label_value: data?.season?.season_data?.quality,
      label_value_ar: data?.season?.season_data?.quality_ar,
    },
    {
      name: "Size",
      name_ar: "الحجم",
      label_value: `${data?.season?.season_data?.actual_size} GB`,
      label_value_ar: `${data?.season?.season_data?.actual_size} جيجا`,
    },
    {
      name: "DVD Quantity",
      name_ar: " عدد ال DVD",
      label_value: data?.season?.season_data?.dvd_quantity || "0",
      label_value_ar: data?.season?.season_data?.dvd_quantity || "0",
    },
    {
      name: "Production Year",
      name_ar: "سنة الانتاج",
      label_value: data?.season?.season_data?.year,
      label_value_ar: data?.season?.season_data?.year,
    },
    {
      name: "Duration in minutes",
      name_ar: "مدة العرض بالدقائق",
      label_value: `${data?.season?.season_data?.duration} min`,
      label_value_ar: `${data?.season?.season_data?.duration} دقيقة`,
    },
    {
      name: "Language",
      name_ar: "اللغة",
      label_value: data?.season?.season_data?.language,
      label_value_ar: data?.season?.season_data?.language_ar,
    },
    {
      name: "Subtitles",
      name_ar: "الترجمة",
      label_value: data?.season?.season_data?.subtitles?.join(", "),
      label_value_ar: data?.season?.season_data?.subtitles_ar?.join(", "),
    },
    {
      name: "Director",
      name_ar: "المخرج",
      label_value: data?.season?.season_data?.directors,
      label_value_ar: data?.season?.season_data?.directors_ar,
    },

    {
      name: "Episodes No",
      name_ar: "عدد الحلقات",
      label_value: `${data?.season?.season_data?.episodes_count}`,
      label_value_ar: `${data?.season?.season_data?.episodes_count}`,
    },

    {
      name: "Notes",
      name_ar: "الملاحظات",
      label_value: data?.season?.season_data?.notes,
      label_value_ar: data?.season?.season_data?.notes,
    },
  ];

  const ActorsSlider = MediaListSlider(ActorCard);

  return (
    <Stack>
      <StyledMainBox
      // sx={{
      //   background: `url(${data?.season?.season_data?.poster}) no-repeat center center / cover`,
      // }}
      >
        <Box
          component="img"
          src={changeImageQuality(data?.season?.season_data?.poster)}
          alt="poster"
          sx={{
            width: "100%",
            height: "100vh",
            objectFit: "fill",
            position: "absolute",
            zIndex: -1,
            "@media (max-width: 1023.99px)": {
              maxHeight: "70vh"
            },
            
            // "@media (max-width: 1023.99px)": {
            //   maxHeight: "36vh"
            // },
            "@media (max-width: 992px)": {
              maxHeight: "50vh"
            },
            
            "@media (max-width: 576px)": {
              maxHeight: "48vh"
            },
          }}
        />
        <Stack
          alignItems="center"
          justifyContent="space-between"
          sx={{
            marginTop: "90vh",
            height: "100%",
            // "@media (max-width: 768px) and (min-height: 600px)": {
            //   marginTop: "200px",
            // },
            // "@media (max-width: 1280px) and (min-height: 800px)": {
            //   marginTop: "40vh",
            // },
            "@media (max-width: 1023.99px)": {
              marginTop: "75vh",
            },
            "@media (max-width: 992px)": {
              marginTop: "60vh",
            },
            "@media (max-width: 768px)": {
              marginTop: "50vh",
            },
            gap: {
              mobile: "4rem",
              sm: "8rem",
              md: "12rem",
              lg: "15rem",
            },
          }}
        >
          <Box
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{
              scale: 0.9,
            }}
            component={motion.img}
            src={playButton}
            alt="play-button"
            sx={{
              cursor: "pointer",
              width: {
                mobile: "80px",
                md: "100px",
                xl: "130px",
              },
              position: "absolute",
              top: "35vh",
              "@media (max-width: 1023.99px)":{
                top: "30vh"
              },
              "@media (max-width: 992px)":{
                top: "20vh"
              }
            }}
            onClick={() => {
              dispatch(
                trailerActions.openModal(
                  data?.season?.season_data?.youtube_code
                )
              );
            }}
          />
          <MainContainer>
            <MediaHeader
              rating={data?.season?.season_data?.avg_rate}
              descAr={data?.season?.season_data?.description_ar}
              descEn={data?.season?.season_data?.description_en}
              image={data?.season?.season_data?.poster}
              thumb={data?.season?.season_data?.poster_thumb}
              tags={tags}
              category={data?.season?.season_data?.category}
              category_ar={data?.season?.season_data?.category_ar}
              noPeopleRated={data?.season?.season_data?.count_rate}
              productId={data?.season?.season_data?.id}
              type="season"
              is_favorite={data?.season?.season_data?.is_favorite}
              {...data?.season?.season_data}
            />
          </MainContainer>
        </Stack>
      </StyledMainBox>
      <StyledSliderContainer>
        <MainContainer>
          <Stack
            spacing={{
              mobile: 5,
              sm: 10,
              md: 15,
              lg: 20,
            }}
          >
            {data?.season?.season_data?.cast?.length > 0 && (
              <StyledSliderWithIcon title={t("actors")}>
                <ActorsSlider
                  items={data?.season?.season_data?.cast}
                  dots={true}
                  small={true}
                />
              </StyledSliderWithIcon>
            )}
            {data?.season?.episodes?.data?.length > 0 && (
              <Stack spacing={6}>
                <TitleWithIcon title={t("episodes")} color="#BB84FF" />
                <MediaListGrid cardsList={data?.season?.episodes?.data} />
                {data?.season?.episodes?.last_page > 1 && (
                  <CustomPagination
                    totalPages={data?.season?.episodes?.last_page}
                    from={data?.season?.episodes?.from}
                    to={data?.season?.episodes?.to}
                    total={data?.season?.episodes?.total}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </MainContainer>
        <RequestBlock />
      </StyledSliderContainer>
    </Stack>
  );
};

export default SeasonPage;
